<template>
  <div style="height: calc(100vh - 85px);overflow: hidden;">
    <iframe id="fpFrame" style="height: 100%; width: 100%;border: 0;overflow: hidden" scrolling="no">
	</iframe>
    <v-fab-transition>
      <v-btn v-if="fpEditorLoaded"
             color="primary"
             fab
             fixed
             bottom
             right
             @click="importToCart"
             :loading="isLoading"
             :disabled="!canImportToCart">
        <v-icon>$putInCart</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import { EventBus } from '@/application/EventBus.js';
import { importFloorplanner } from '@/modules/configurator/api';
import { initializeFloorplanner, loadScripts } from '@/modules/configurator/util/floorplanner.js';
import { mapGetters } from 'vuex';

export default {
  name: 'configuratorView',
  data() {
    return {
      canImportToCart: true,
      isLoading: false,
      fpEditorLoaded: false,
      customStyle: {
        light: '#69be28',
        dark: '#69be28',
      },
    };
  },
  computed: {
    ...mapGetters('settings', {
      floorplannerId: 'getFloorplannerId',
      storeId: 'getCurrentStoreId',
    }),
  },
  watch: {
    async storeId() {
      this.initializeFloorplannerFrame();
    },
  },
  async mounted() {
    const that = this;
    window.onmessage = function (e){
      if (e.data.event === 'fp-editor-loaded'){
        that.fpEditorLoaded = true;
      } else if (e.data.event === 'floorplanner-loaded'){
        that.floorplannerLoaded = true;
      } else if (e.data.event === 'floorplanner-updated'){
        that.handleFloorplanUpdate();
      }
    };
    this.initializeFloorplannerFrame();
  },
  methods: {
    async importToCart() {
      this.isLoading = true;
      try {
        // TODO: invoke save of iframe.
        // this.fpEditor.save();
        // this.saveFPEditor();
        document.getElementById('fpFrame').contentWindow.postMessage({'event': 'fp-save'});
        await importFloorplanner(this.storeId, { floorplannerProjectId: this.floorplannerId });
        this.$router.push({ name: 'shoppingCart' });
      } catch {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.configuratorImport')]),
        });
      }
      this.isLoading = false;
      this.canImportToCart = false;
    },
    handleFloorplanUpdate() {
      if (!this.canImportToCart) this.canImportToCart = true;
    },
    initializeFloorplannerFrame(){
      let fpFrame = document.getElementById('fpFrame');
      fpFrame.src = "fp-frame?storeid=" + this.storeId + "&floorplannerid=" + this.floorplannerId;
    },
    saveFPEditor(){
      let fpFrame = document.getElementById('fpFrame');
      fpFrame.contentWindow.postMessage('save-fp-edits');
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
